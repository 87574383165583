import { useState, useEffect } from 'react'
import { Row, Col, Card, FormControl } from 'react-bootstrap'
import { pinata } from '../config'

const Home = ({ nft }) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('') // State for search query
  const [filteredItems, setFilteredItems] = useState([]) // State for filtered items

  const loadMarketplaceItems = async () => {
    try {
      const itemCount = Number(await nft?.tokenCount())
      if (!itemCount || itemCount <= 0) {
        console.error("Error: Invalid token count:", itemCount);
        setLoading(false);
        return;
      }

      let items = []
      for (let i = 1; i <= itemCount; i++) {
        const uri = await nft.tokenURI(i)
        // Extract the IPFS hash from the URI
        const ipfsHash = uri.replace('ipfs://', '')
        // use uri to fetch the nft metadata stored on ipfs 
        const metadata = (await pinata.gateways.get(ipfsHash)).data
  
        // Add item to items array
        items.push({
          name: metadata.name,
          description: metadata.description,
          rarity: metadata.rarity,
          condition: metadata.condition,
          roi: metadata.roi,
          image: `https://ipfs.io/ipfs/${metadata?.image.replace('ipfs://', '')}`
        })
      }
      
      setItems(items)
      setFilteredItems(items) // Initialize filteredItems with all items
      setLoading(false)
    } catch (error) {
      console.error("Error fetching items: ", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    loadMarketplaceItems()
  }, [])

  // Filter items based on the search query
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredItems(items) // If no search query, show all items
    } else {
      const lowercasedQuery = searchQuery.toLowerCase()
      const filtered = items.filter(item => 
        item.name.toLowerCase().includes(lowercasedQuery) ||
        item.description.toLowerCase().includes(lowercasedQuery) ||
        item.rarity.toLowerCase().includes(lowercasedQuery)
      )
      setFilteredItems(filtered)
    }
  }, [searchQuery, items])

  if (loading) return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Loading...</h2>
    </main>
  )

  return (
    <div className="flex justify-center">
      <div className="d-flex justify-content-center py-3">
          <FormControl
            type="text"
            placeholder="Search NFTs by name, description, rarity..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: '50%' }} // Adjust the width of the search bar
          />
        </div>
      {filteredItems.length > 0 ?
        <div className="px-5 container">
          <Row xs={1} md={2} lg={4} className="g-4 py-4">
            {filteredItems.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">
                <Card>
                  <Card.Img variant="top" width={10} height={150} src={item.image} />
                  <Card.Body color="secondary">
                    <Card.Title>Metadata</Card.Title>
                    <hr />
                    <Card.Text>Name: {item.name}</Card.Text>
                    <Card.Text>
                      Description: {item.description}
                    </Card.Text>
                    <Card.Text>Condition: {item.condition}</Card.Text>
                    <Card.Text>Rarity: {item.rarity}</Card.Text>
                    <Card.Text>Roi: {item.roi}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        : (
          <main style={{ padding: "1rem 0" }}>
            <h2>No listed assets</h2>
          </main>
        )}
    </div>
  );
}
export default Home
