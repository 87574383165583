import { useState } from 'react'
// import { ethers } from "ethers"
import { Row, Form, Button } from 'react-bootstrap'
import { pinata } from '../config'

const Create = ({ nft }) => {
  const [image, setImage] = useState('')
  const [price, setPrice] = useState(null)
  const [name, setName] = useState('')
  const [rarity, setRarity] = useState("")
  const [condition, setCondition] = useState("")
  const [roi, setRoi] = useState(0)
  const [description, setDescription] = useState('')

  const uploadToIPFS = async (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    if (typeof file !== 'undefined') {
      try {
        const result = await pinata.upload.file(file)
        setImage(`ipfs://${result.IpfsHash}`)
      } catch (error){
        console.log("ipfs image upload error: ", error)
      }
    }
  }
  const createNFT = async () => {
    if (!image || !price || !name || !description || !rarity || !condition || !roi) 
      return
    try{
      const result = await pinata.upload.json({
        name: name,
        image: image,
        price: price,
        description: description,
        rarity: rarity,
        condition: condition,
        roi: roi
      })
      mintThenList(result)
    } catch(error) {
      console.log("ipfs uri upload error: ", error)
    }
  }
  const mintThenList = async (result) => {
    try {
      // mint nft 
      await nft.mint(`ipfs://${result.IpfsHash}`)
      // get tokenId of new nft 
      await nft.tokenCount()
    } catch (error) {
      console.log("mint nft error: ", error)
    }
  }
  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <main role="main" className="col-lg-12 mx-auto" style={{ maxWidth: '1000px' }}>
          <div className="content mx-auto">
            <Row className="g-4">
              <Form.Control
                type="file"
                required
                name="file"
                onChange={uploadToIPFS}
              />
              <Form.Control onChange={(e) => setName(e.target.value)} size="lg" required type="text" placeholder="Name" />
              <Form.Control onChange={(e) => setDescription(e.target.value)} size="lg" required as="textarea" placeholder="Description" />
              <Form.Control onChange={(e) => setPrice(e.target.value)} size="lg" required type="number" placeholder="Price in ETH" />
              <Form.Control onChange={(e) => setRarity(e.target.value)} size="lg" required type="text" placeholder="Rarity" />
              <Form.Control onChange={(e) => setCondition(e.target.value)} size="lg" required type="text" placeholder="Condition" />
              <Form.Control onChange={(e) => setRoi(e.target.value)} size="lg" required type="number" placeholder="ROI" />
              <div className="d-grid px-0">
                <Button onClick={createNFT} variant="primary" size="lg">
                  Mint NFT!
                </Button>
              </div>
            </Row>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Create